.default-modal-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

}

.default-modal-content {
    position: relative;
    padding: 0.5rem;
    padding-top: 2.5rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.2);
}

.default-modal-close{
    cursor: pointer;
    position: absolute;
    top: .4rem;
    right: .5rem;
    width: 2rem;
    height: 2rem;
}

.default-modal-close > svg {
    
    pointer-events: none;
}
